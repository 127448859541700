var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[(_vm.resolutionScreen >= 500)?_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"700px"},model:{value:(_vm.shower),callback:function ($$v) {_vm.shower=$$v},expression:"shower"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("sentFileOnechat.header"))),_c('v-spacer'),_c('v-icon',{attrs:{"color":_vm.color.theme,"dense":"","dark":"","size":"35"}},[_vm._v("mdi-chat")])],1),_c('v-divider'),_c('v-card-text',[_c('br'),_c('div',{staticClass:"text-center"},[_c('v-chip',{staticClass:"pt-4 pl-2 ma-2"},[_c('p',[_c('v-icon',{attrs:{"color":_vm.color.theme}},[_vm._v(" people_alt")]),_c('b',[_vm._v(" "+_vm._s(_vm.count_user)+" คน ")])],1)])],1),_c('v-list',_vm._l((_vm.showtosend),function(item){return _c('v-list-item',{key:item.title},[_c('v-list-item-action',{on:{"mouseover":function($event){item.show_name = true},"mouseleave":function($event){item.show_name = false}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" "+_vm._s(item.user_icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{on:{"mouseover":function($event){item.show_name = true},"mouseleave":function($event){item.show_name = false}}},[_vm._v(" "+_vm._s(item.display_name)+" ")]),_c('v-list-item-subtitle',[(
                    _vm.check_show_progress === true &&
                      item.send_value !== 100 &&
                      item.send_value !== 500
                  )?_c('v-progress-linear',{attrs:{"value":item.send_value,"color":"light-blue"}}):_vm._e()],1)],1),_c('v-list-item-action',[(item.send_value !== 500)?_c('v-chip',{staticClass:"ma-2 font-weight-bold",attrs:{"color":_vm.color.theme,"label":"","outlined":""}},[_vm._v(" "+_vm._s(item.send_status)+" ")]):_c('v-chip',{staticClass:"ma-2 font-weight-bold",attrs:{"color":"red","label":"","outlined":""}},[_vm._v(" "+_vm._s(item.send_status)+" ")])],1)],1)}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(_vm.check_show_progress === false && _vm.check_show_button === false)?_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){_vm.$emit('closedialog'), (_vm.countuser = [])}}},[_vm._v(_vm._s(_vm.$t("sentFileOnechat.close")))]):_vm._e(),(_vm.check_show_progress === false && _vm.check_show_button === false)?_c('v-btn',{attrs:{"color":_vm.color.theme,"dark":_vm.color.darkTheme},on:{"click":function($event){_vm.fn_sendonechat(),
              (_vm.check_show_button = true),
              (_vm.check_show_progress = true),
              (_vm.countuser = [])}}},[_vm._v(_vm._s(_vm.$t("sentFileOnechat.send")))]):_vm._e(),(_vm.check_show_button === true)?_c('v-btn',{attrs:{"color":"red","disabled":_vm.check_dis_button,"dark":""},on:{"click":function($event){_vm.$emit('closedialogreload'), (_vm.countuser = [])}}},[_vm._v(_vm._s(_vm.$t("sentFileOnechat.close")))]):_vm._e()],1)],1)],1):_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":_vm.maxWidthOnMobile,"content-class":"rounded-lg"},model:{value:(_vm.shower),callback:function ($$v) {_vm.shower=$$v},expression:"shower"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-layout',{staticClass:"pt-2 pb-4"},[_c('v-flex',{staticClass:"ml-8",attrs:{"d-flex":"","justify-center":""}},[_c('span',{style:(_vm.headerPage)},[_vm._v(_vm._s(_vm.$t("sentFileOnechat.header")))])]),_c('v-flex',{attrs:{"xs1":"","d-flex":"","justify-end":""}},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? 'white' : _vm.color.theme},on:{"click":function($event){_vm.$emit('closedialog'), (_vm.countuser = [])}}},[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('div',{staticClass:"text-center"},[_c('v-chip',{staticClass:"pt-4 pl-2 mx-2"},[_c('p',[_c('v-icon',{attrs:{"color":_vm.color.theme}},[_vm._v(" people_alt")]),_c('b',[_vm._v(" "+_vm._s(_vm.count_user)+" คน ")])],1)])],1),_c('v-list',_vm._l((_vm.showtosend),function(item){return _c('v-list-item',{key:item.title},[_c('v-list-item-action',{on:{"mouseover":function($event){item.show_name = true},"mouseleave":function($event){item.show_name = false}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" "+_vm._s(item.user_icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{on:{"mouseover":function($event){item.show_name = true},"mouseleave":function($event){item.show_name = false}}},[_vm._v(" "+_vm._s(item.display_name)+" ")]),_c('v-list-item-subtitle',[(
                    _vm.check_show_progress === true &&
                      item.send_value !== 100 &&
                      item.send_value !== 500
                  )?_c('v-progress-linear',{attrs:{"value":item.send_value,"color":"light-blue"}}):_vm._e()],1)],1),_c('v-list-item-action',[(item.send_value !== 500)?_c('v-chip',{staticClass:"ma-2 font-weight-bold",attrs:{"color":_vm.color.theme,"label":"","outlined":""}},[_vm._v(" "+_vm._s(item.send_status)+" ")]):_c('v-chip',{staticClass:"ma-2 font-weight-bold",attrs:{"color":"red","label":"","outlined":""}},[_vm._v(" "+_vm._s(item.send_status)+" ")])],1)],1)}),1)],1),_c('div',{staticClass:"text-center my-4"},[(_vm.check_show_progress === false && _vm.check_show_button === false)?_c('v-btn',{staticClass:"mr-6",style:(_vm.btnAction),attrs:{"height":"40px","width":"40%","color":"red","outlined":""},on:{"click":function($event){_vm.$emit('closedialog'), (_vm.countuser = [])}}},[_vm._v(_vm._s(_vm.$t("sentFileOnechat.close")))]):_vm._e(),(_vm.check_show_progress === false && _vm.check_show_button === false)?_c('v-btn',{staticClass:"elevation-0",style:(_vm.btnAction),attrs:{"height":"40px","width":"40%","color":_vm.color.theme,"dark":""},on:{"click":function($event){_vm.summitsendonechat(), _vm.$emit('openDrag')}}},[_vm._v(_vm._s(_vm.$t("sentFileOnechat.send")))]):_vm._e(),(_vm.check_show_button === true)?_c('v-btn',{attrs:{"color":"red","disabled":_vm.check_dis_button,"dark":""},on:{"click":function($event){_vm.$emit('closedialogreload'), (_vm.countuser = [])}}},[_vm._v(_vm._s(_vm.$t("sentFileOnechat.close")))]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }